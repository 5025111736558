






import {Vue, Component} from 'vue-property-decorator';
import AideRestaurant from '@/components/Aide/AideRestaurant.vue';


@Component({
    components: {
        AideRestaurant
    }
})
export default class extends Vue {}
